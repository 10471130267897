<template>
  <div>
    <v-col cols="2">
      <v-select
        :items="years"
        v-model="selectedYear"
        @change="year = selectedYear"
        outlined
        dense
      ></v-select>
    </v-col>
    <!-- <v-tabs>
      <v-tab
        v-for="year in years"
        :key="year + 'tabinregisterTitle'"
        @click="selectedYear = year"
        >{{ year }}</v-tab
      >
      <v-tab-item
        v-for="year in years"
        :key="year + 'tabinregisterContents'"
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <ProjectsRegisterTab
          :year="year"
          :selectedYear="selectedYear"
          :select-mode="selectMode"
          @select="$emit('select', $event)"
          :projectsSums="projectsSums"
        ></ProjectsRegisterTab>
      </v-tab-item>
    </v-tabs> -->
    <ProjectsRegisterTab
      :year="selectedYear"
      :selectedYear="selectedYear"
      :select-mode="selectMode"
      @select="$emit('select', $event)"
      :projectsSums="projectsSums"
    ></ProjectsRegisterTab>
  </div>
</template>

<script>
import ProjectsRegisterTab from '@/components/register/ProjectsRegisterTab';

export default {
  name: 'ProjectsRegister',
  props: ['selectMode', 'projectsSums'],
  data() {
    return {
      years: [2021, 2022, 2023, 2024, 2025, 2026],
      selectedYear: new Date().getFullYear(),
      year: new Date().getFullYear(),
    };
  },
  filters: {},
  watch: {
    year() {
      console.log('project registry year', this.year);
      this.$forceUpdate();
    },
    selectedYear() {
      console.log('project registry selected year', this.selectedYear);
    },
  },
  methods: {},
  beforeMount() {},
  components: { ProjectsRegisterTab },
};
</script>

<style scoped>
.v-tabs:not(.v-tabs--vertical) .v-tab {
  letter-spacing: normal;
  padding: 0 10px;
}
</style>
