<template>
  <v-container>
    <h1>
      <span>Проекты</span>
      <div class="search-wrap">
        <v-btn @click="$router.push('/projectEdit/')">Создать</v-btn>
      </div>
    </h1>

    <div class="projects-info">
      <div>
        <h3>Общая сумма контрактов</h3>
        <h4>
          План:
          {{
            projectsSums.contractSums
              ? projectsSums.contractSums.plan.toLocaleString('ru')
              : 0
          }}
          ₽
        </h4>
        <h4>
          Факт:
          {{
            projectsSums.contractSums
              ? projectsSums.contractSums.fact.toLocaleString('ru')
              : 0
          }}
          ₽
        </h4>
      </div>
      <div>
        <h3>Сумма Vi план</h3>
        <h4>
          {{
            projectsSums.viContractSums
              ? projectsSums.viContractSums.plan.toLocaleString('ru')
              : 0
          }}
          ₽
        </h4>
      </div>
      <div>
        <h3>Сумма Vi факт</h3>
        <h4>
          {{
            projectsSums.viContractSums
              ? projectsSums.viContractSums.fact.toLocaleString('ru')
              : 0
          }}
          ₽
        </h4>
      </div>
      <div>
        <h3>Сумма Pri план</h3>
        <h4>
          {{
            projectsSums.priProjectProfitSums
              ? projectsSums.priProjectProfitSums.plan.toLocaleString('ru')
              : 0
          }}
          ₽
        </h4>
      </div>
      <div>
        <h3>Сумма Pri факт</h3>
        <h4>
          {{
            projectsSums.priProjectProfitSums
              ? projectsSums.priProjectProfitSums.fact.toLocaleString('ru')
              : 0
          }}
          ₽
        </h4>
      </div>
      <div>
        <h3>Доля в прибыли</h3>
        <h4>
          План:
          {{
            projectsSums.priProjectProfitSums
              ? (
                  (projectsSums.priProjectProfitSums.plan /
                    projectsSums.contractSums.plan) *
                  100
                ).toFixed(2)
              : 0
          }}
          %
        </h4>
        <h4>
          Факт:
          {{
            projectsSums.priProjectProfitSums
              ? (
                  (projectsSums.priProjectProfitSums.fact /
                    projectsSums.contractSums.fact) *
                  100
                ).toFixed(2)
              : 0
          }}
          %
        </h4>
      </div>
    </div>

    <ProjectsRegister
      :select-mode="false"
      :projectsSums="projectsSums"
    ></ProjectsRegister>
  </v-container>
</template>

<script>
import { projectKeyHolder } from '@/modules/Projects';
import ProjectsRegister from '@/components/register/ProjectsRegister';

export default {
  name: 'ProjectsComponent',
  props: {},
  components: { ProjectsRegister },
  data() {
    return {
      projectsSums: {
        contractSums: { plan: 0, fact: 0 },
        viContractSums: { plan: 0, fact: 0 },
        hiContractSums: { plan: 0, fact: 0 },
        priProjectProfitSums: { plan: 0, fact: 0 },
      },
    };
  },
  methods: {},
  async beforeMount() {
    if (!projectKeyHolder.checkOrRequestKey()) return;
  },
};
</script>
